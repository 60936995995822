<template>
  <div class="w-per100 bg_1D1D1D white_color ft16 px360">
    <div class="py50 row j_sb">
      <div>
        <div class="">
          <img :src="bottom_logo" alt=""
            class="logow" />
        </div>
      </div>
      <div class="row a_center ft16">
        <div class="ml10">
          <div class="mb15" @click="handleShow(0)">就业拓展</div>
          <div class="mb15" @click="handleShow(1)">校企活动</div>
          <div class="mb15" @click="handleShow(2)">学生发布</div>
          <div class="mb15" @click="handleShow(3)">信息发布</div>
        </div>
        <div class="ml120">
          <div class="mb34">邮箱：{{ web_email }}</div>
          <div class="mb34">微博：{{ web_weibo }}</div>
          <div class="mb15">微信：{{ web_weixin }}</div>
        </div>
      </div>
      <div class="row a_center">
        <div class="">
          <img :src="web_xcx" alt="" class="wh110" />
          <div class="ft14 white_color mt12 text_center">微信小程序</div>
        </div>
        <div class="ml46">
          <img :src="web_gzh" alt="" class="wh110" />
          <div class="ft14 white_color mt12 text_center ">扫码关注公众号</div>
        </div>
      </div>
    </div>
    <div class="py30 bdt_FFFFFF ft14 info_color text_center pointer" @click="tobeian">
      {{web_beian}}
    </div>
  </div>
</template>
<script>
import { webInfo } from '@/api/app'
export default {
  data() {
    return {
      bottom_logo:'',
      web_email:'',
      web_weibo:'',
      web_weixin:'',
      web_xcx:'',
      web_gzh:'',
      web_beian:'',
      web_beian_url:'',
    };
  },
  props: {
    // current: Number,
  },
  mounted() {
    this.getwebInfo()
  },
  methods: {
    tobeian(){
      window.open(this.web_beian_url, '_blank');
    },
    async getwebInfo() { //个人信息
      let { data } = await webInfo()
      if (data.code == 1) {
        this.bottom_logo = data.data.bottom_logo;
        this.web_email = data.data.web_email;
        this.web_weibo = data.data.web_weibo;
        this.web_weixin = data.data.web_weixin;
        this.web_xcx = data.data.web_xcx;
        this.web_gzh = data.data.web_gzh;
        this.web_beian = data.data.web_beian;
        this.web_beian_url = data.data.web_beian_url;
      }
    },
    handleShow(index) {  //切换
      if (index == 0) {
        this.$router.push({
          path: "/SchoolIndex",
        });
      } else if (index == 1) {
        this.$router.push({
          path: "/Powder",
        });
      }
      else if (index == 2) {
        this.$router.push({
          path: "/ManageForms",
        });
      }
      else if (index == 3) {
        this.$router.push({
          path: "/MessageRecruit",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.logow {
  width: 12.5rem;
  height: 10rem;
}
</style>
