import Vue from 'vue'
import VueRouter from 'vue-router'
// 把这段代码直接粘贴到router/index.js中的Vue.use(VueRouter)之前
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};
Vue.use(VueRouter); //使用插件

export const routes = [
  { //一级路由
    path: '/', // 根路由 
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {//初始化的meta
      isShow: true,
      title: '',
      content: {
        title: '',
        keywords: '',
        description: '',
      }
    }
    // redirect: "/index", //重定向
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/FindPassword',
    name: 'FindPassword',
    component: () => import('../views/FindPassword.vue'),
  },
  
  // 学校方
  { //主页
    path: '/SchoolIndex',
    name: 'SchoolIndex',
    component: () => import('../views/School/SchoolIndex.vue'),
  },
  { //校企活动
    path: '/SchoolActivity',
    name: 'SchoolActivity',
    component: () => import('../views/School/SchoolActivity.vue'),
    children: [
      {//加粉企业
        path: '/Powder',
        name: 'Powder',
        component: () => import('../views/School/Powder.vue'),
      },
      {//加粉企业基本信息
        path: '/PowderMessage',
        name: 'PowderMessage',
        component: () => import('../views/School/PowderMessage.vue'),
      },
      {//加粉企业基本信息
        path: '/PowderDetails',
        name: 'PowderDetails',
        component: () => import('../views/School/PowderDetails.vue'),
      },
      {//推荐发现
        path: '/found',
        name: 'found',
        component: () => import('../views/School/found.vue'),
      },
      // {//学生就业
      //   path: '/StudentEmployment',
      //   name: 'StudentEmployment',
      //   component: () => import('../views/School/StudentEmployment.vue'),
      // },
    ]
  },
  { //学生管理
    path: '/SchoolStudent',
    name: 'SchoolStudent',
    component: () => import('../views/School/SchoolStudent.vue'),
    children: [
      {//学生管理
        path: '/ManageForms',
        name: 'ManageForms',
        component: () => import('../views/School/ManageForms.vue'),
      },//ExercitationForms
      {//就业管理
        path: '/EmploymentForms',
        name: 'EmploymentForms',
        component: () => import('../views/School/EmploymentForms.vue'),
      },
      {//实习管理
        path: '/ExercitationForms',
        name: 'ExercitationForms',
        component: () => import('../views/School/ExercitationForms.vue'),
      },//EmploymentForms
      {//签约管理
        path: '/ManageContracts',
        name: 'ManageContracts',
        component: () => import('../views/School/ManageContracts.vue'),
      },
      {//学生就业
        path: '/StudentEmployment',
        name: 'StudentEmployment',
        component: () => import('../views/School/StudentEmployment.vue'),
      },
    ]
  },
  {//企业招聘信息
    path: '/SchoolRecruit',
    name: 'SchoolRecruit',
    component: () => import('../views/School/SchoolRecruit.vue'),
    children:[
      {//企业招聘信息
        path: '/MessageRecruit',
        name: 'MessageRecruit',
        component: () => import('../views/School/MessageRecruit.vue'),
      },
    ]
  },
  { //消息发布
    path: '/SchoolMessage',
    name: 'SchoolMessage',
    component: () => import('../views/School/SchoolMessage.vue'),
    children: [
      // {//企业招聘信息
      //   path: '/MessageRecruit',
      //   name: 'MessageRecruit',
      //   component: () => import('../views/School/MessageRecruit.vue'),
      // },
      {//发布合作项目
        path: '/MessageCooperate',
        name: 'MessageCooperate',
        component: () => import('../views/School/MessageCooperate.vue'),
      },
      {//项目详情
        path: '/MessageDetails',
        name: 'MessageDetails',
        component: () => import('../views/School/MessageDetails.vue'),
      },
      {//发布招聘专场
        path: '/MessageSpecial',
        name: 'MessageSpecial',
        component: () => import('../views/School/MessageSpecial.vue'),
      },
      {//发布双选会
        path: '/MessageDouble',
        name: 'MessageDouble',
        component: () => import('../views/School/MessageDouble.vue'),
      },
      {//发布资讯
        path: '/MessageNews',
        name: 'MessageNews',
        component: () => import('../views/School/MessageNews.vue'),
      },
    ]
  },
  { //我的发布活动详情
    path: '/ActivityDetails',
    name: 'ActivityDetails',
    component: () => import('../views/School/ActivityDetails.vue'),
  },
  { //岗位详情
    path: '/postsDetails',
    name: 'postsDetails',
    component: () => import('../views/School/postsDetails.vue'),
  },
  { //更多 招聘信息
    path: '/RecruitMore',
    name: 'RecruitMore',
    component: () => import('../views/School/RecruitMore.vue'),
  },
  { //更多 收到申请
    path: '/ApplyMore',
    name: 'ApplyMore',
    component: () => import('../views/School/ApplyMore.vue'),
  },
  { //更多 发布活动
    path: '/ActivityMore',
    name: 'ActivityMore',
    component: () => import('../views/School/ActivityMore.vue'),
  },
  {//个人中心
    path: '/CenterPersonal',
    name: 'CenterPersonal',
    component: () => import('../views/School/CenterPersonal.vue'),
  },
  {//上传合同
    path: '/AddFile',
    name: 'AddFile',
    component: () => import('../views/School/addPDF.vue'),
  },
  {//个人中心修改
    path: '/EditSchool',
    name: 'EditSchool',
    component: () => import('../views/School/EditSchool.vue'),
  },{//查看合作
    path: '/cooperateSpecial',
    name: 'cooperateSpecial',
    component: () => import('../views/School/cooperateSpecial.vue'),
  },






  //企业端
  {//主页
    path: '/CompanyIndex',
    name: 'CompanyIndex',
    component: () => import('../views/Company/CompanyIndex.vue'),
  },
  {//简历详情
    path: '/ResumeDetails',
    name: 'ResumeDetails',
    component: () => import('../views/Company/ResumeDetails.vue'),
  },
  {//活动详情
    path: '/CActivityDetails',
    name: 'CActivityDetails',
    component: () => import('../views/Company/CActivityDetails.vue'),
  },
  {//合作项目详情
    path: '/CooperateDetails',
    name: 'CooperateDetails',
    component: () => import('../views/Company/CooperateDetails.vue'),
  },
  {//校企活动
    path: '/CompanyActivity',
    name: 'CompanyActivity',
    component: () => import('../views/Company/CompanyActivity.vue'),
    children: [
      {//加分院校
        path: '/CPowder',
        name: 'CPowder',
        component: () => import('../views/Company/CPowder.vue'),
      },
      {//加粉院校基本信息
        path: '/CPowderMessage',
        name: 'CPowderMessage',
        component: () => import('../views/Company/CPowderMessage.vue'),
      },//CPowderDetails
      {//加粉院校基本信息合作详情
        path: '/CPowderDetails',
        name: 'CPowderDetails',
        component: () => import('../views/Company/CPowderDetails.vue'),
      },
      {//推荐发现
        path: '/Cfound',
        name: 'Cfound',
        component: () => import('../views/Company/Cfound.vue'),
      },
    ]
  },
  {//简历招聘
    path: '/CompanyResume',
    name: 'CompanyResume',
    component: () => import('../views/Company/CompanyResume.vue'),
    children: [
      {//简历管理
        path: '/ManageResume',
        name: 'ManageResume',
        component: () => import('../views/Company/ManageResume.vue'),
      },
      {//简历管理
        path: '/DetailsResume',
        name: 'DetailsResume',
        component: () => import('../views/Company/DetailsResume.vue'),
      },
      {//招聘数据
        path: '/CompanyEmployment',
        name: 'CompanyEmployment',
        component: () => import('../views/Company/CompanyEmployment.vue'),
      }
    ]
  },
  {//消息发布
    path: '/CompanyMessage',
    name: 'CompanyMessage',
    component: () => import('../views/Company/CompanyMessage.vue'),
    children: [
      {//智能推送
        path: '/SmartPush',
        name: 'SmartPush',
        component: () => import('../views/Company/SmartPush.vue')
      },
      {//合作项目详情
        path: '/SmartDetails',
        name: 'SmartDetails',
        component: () => import('../views/Company/SmartDetails.vue')
      },
      {//发布招聘
        path: '/PushRecruit',
        name: 'PushRecruit',
        component: () => import('../views/Company/PushRecruit.vue')
      },
      {//新增发布
        path: '/addRecruit',
        name: 'addRecruit',
        component: () => import('../views/Company/addRecruit.vue')
      }
    ]
  },
  {//个人中心
    path: '/MyCenter',
    name: 'MyCenter',
    component: () => import('../views/Company/MyCenter.vue'),
  },
  {//查看申请专场
    path: '/ApplySpecial',
    name: 'ApplySpecial',
    component: () => import('../views/Company/ApplySpecial.vue'),
  },
  {//编辑信息
    path: '/EditMy',
    name: 'EditMy',
    component: () => import('../views/Company/EditMy.vue'),
  },
  {//上传合同
    path: '/UploadFile',
    name: 'UploadFile',
    component: () => import('../views/Company/addPDF.vue'),
  },
  {
    path: '*',
    component: () => import('../views/NoFound.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash' //路由匹配模式  hash 
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  next();
});
/* 
  权限控制：把不需要登录的页面 称为 白名单 

*/

export default router
